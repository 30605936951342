import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TopBar from './TopBar';
import Footer from './Footer';
import { db, auth, signInWithGoogle } from "../firebase";
import { collection, getDocs, getDoc, doc, writeBatch, setDoc } from 'firebase/firestore';
import './YouTubeWrapped.css';
import html2canvas from 'html2canvas';
import { getLikedVideos } from '../api/subscriptions';

const YouTubeWrapped = () => {
    const navigate = useNavigate();
    const [wrappedData, setWrappedData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedChannelId, setExpandedChannelId] = useState(null);
    const [channelVideos, setChannelVideos] = useState({});
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        const checkExistingWrapped = async () => {
            try {
                if (!auth.currentUser) {
                    setIsLoading(false);
                    return;
                }

                // First check if user has liked videos collection
                const likedVideosRef = collection(db, "users", auth.currentUser.uid, "likedVideos");
                const likedVideosSnapshot = await getDocs(likedVideosRef);

                if (likedVideosSnapshot.empty) {
                    // If no liked videos exist, we need to fetch them first
                    setIsLoading(false);
                    return;
                }

                // Then check for wrapped data
                const wrappedRef = collection(db, "users", auth.currentUser.uid, "wrapped2024");
                const wrappedSnapshot = await getDocs(wrappedRef);

                if (!wrappedSnapshot.empty) {
                    const wrappedData = {};
                    wrappedSnapshot.forEach(doc => {
                        wrappedData[doc.id] = doc.data();
                    });

                    setWrappedData({
                        totalVideos: wrappedData.stats.totalVideos,
                        totalChannels: wrappedData.stats.totalChannels,
                        channelStats: wrappedData.channelStats.channels
                    });
                }
            } catch (error) {
                console.error("Error checking for existing data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        // Listen for auth state changes
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                checkExistingWrapped();
            } else {
                setWrappedData(null);
                setIsLoading(false);
            }
        });

        return () => unsubscribe();
    }, []); // Empty dependency array since we want this to run once on mount

    const processLikedVideos = async () => {
        setIsLoading(true);
        try {
            // Log feedback first
            const feedbackRef = collection(db, "feedback");
            await setDoc(doc(feedbackRef), {
                userId: auth.currentUser.uid,
                feedback: feedback.trim(),
                timestamp: new Date().toISOString(),
                userEmail: auth.currentUser.email // optional, if you want to track who gave feedback
            });

            // Check if liked videos exist
            const likedVideosRef = collection(db, "users", auth.currentUser.uid, "likedVideos");
            const likedVideosSnapshot = await getDocs(likedVideosRef);

            if (likedVideosSnapshot.empty) {
                // If no liked videos exist, fetch them first
                await getLikedVideos();

                // Check again after fetching
                const newSnapshot = await getDocs(likedVideosRef);
                if (newSnapshot.empty) {
                    throw new Error("No liked videos found after fetching. Sorry about that :(");
                }
            }

            // Now proceed with wrapped processing
            const wrappedRef = collection(db, "users", auth.currentUser.uid, "wrapped2024");
            const wrappedSnapshot = await getDocs(wrappedRef);

            if (!wrappedSnapshot.empty) {
                const wrappedData = {};
                wrappedSnapshot.forEach(doc => {
                    wrappedData[doc.id] = doc.data();
                });

                // Add safety check
                if (!wrappedData.stats || !wrappedData.channelStats?.channels) {
                    throw new Error("Invalid wrapped data structure");
                }

                setWrappedData({
                    totalVideos: wrappedData.stats.totalVideos || 0,
                    totalChannels: wrappedData.stats.totalChannels || 0,
                    channelStats: wrappedData.channelStats.channels || []
                });

            } else {
                // Calculate new wrapped data
                const videosRef = collection(db, "users", auth.currentUser.uid, "likedVideos");
                const querySnapshot = await getDocs(videosRef);

                const videos2024 = [];
                const channelMap = new Map();

                querySnapshot.forEach(doc => {
                    const video = doc.data();
                    if (!video) return; // Skip if video data is invalid

                    const publishDate = new Date(video.publishedAt);

                    if (publishDate.getFullYear() === 2024 &&
                        video.channelTitle &&
                        video.channelTitle !== 'Unknown Channel' &&
                        video.channelId) { // Add channelId check
                        videos2024.push(video);

                        if (!channelMap.has(video.channelId)) {
                            channelMap.set(video.channelId, {
                                name: video.channelTitle,
                                videoCount: 1,
                                videos: [video]
                            });
                        } else {
                            const channelData = channelMap.get(video.channelId);
                            channelMap.set(video.channelId, {
                                ...channelData,
                                videoCount: channelData.videoCount + 1,
                                videos: [...channelData.videos, video]
                            });
                        }
                    }
                });

                // Check if we have any valid data
                if (videos2024.length === 0) {
                    throw new Error("No videos found for 2024");
                }

                const channelArray = Array.from(channelMap.entries())
                    .map(([channelId, data]) => ({
                        channelId,
                        name: data.name,
                        videoCount: data.videoCount,
                        videos: data.videos
                    }))
                    .sort((a, b) => b.videoCount - a.videoCount);

                // Store the wrapped data in Firestore
                const batch = writeBatch(db);

                // Store overall stats (now excluding unknown channels)
                batch.set(doc(wrappedRef, 'stats'), {
                    totalVideos: videos2024.length,
                    totalChannels: channelMap.size,
                    generatedAt: new Date().toISOString()
                });

                // Store channel stats
                batch.set(doc(wrappedRef, 'channelStats'), {
                    channels: channelArray.map(({ videos, ...channelData }) => channelData)
                });

                // Store individual channel data
                channelArray.forEach(channel => {
                    batch.set(doc(wrappedRef, `channel_${channel.channelId}`), {
                        ...channel
                    });
                });

                await batch.commit();

                setWrappedData({
                    totalVideos: videos2024.length,
                    totalChannels: channelMap.size,
                    channelStats: channelArray
                });
            }

        } catch (error) {
            console.error("Error processing liked videos:", error);
            setError(error.message || "Unable to process liked videos. Please make sure your liked videos playlist is not private.");
        } finally {
            setIsLoading(false);
        }
    };

    const getChannelVideos = async (channelId) => {
        try {
            const channelDoc = await getDoc(
                doc(db, "users", auth.currentUser.uid, "wrapped2024", `channel_${channelId}`)
            );

            if (channelDoc.exists()) {
                const channelData = channelDoc.data();
                setChannelVideos(prev => ({
                    ...prev,
                    [channelId]: channelData.videos.sort((a, b) =>
                        new Date(b.publishedAt) - new Date(a.publishedAt)
                    )
                }));
            }
        } catch (error) {
            console.error("Error fetching channel videos:", error);
        }
    };

    const generateShareImage = async () => {
        const shareableContent = document.getElementById('wrapped-shareable');
        try {
            // Get the profile image
            const profileImg = document.querySelector('.wrapped-share-avatar');
            const originalSrc = profileImg.src;

            // Create a new image with crossOrigin set
            await new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = "anonymous";  // This is important
                img.onload = () => {
                    // Create a canvas to get the data URL
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);

                    try {
                        // Try to get data URL and set it
                        profileImg.src = canvas.toDataURL();
                        resolve();
                    } catch (e) {
                        // If it fails, just resolve anyway
                        resolve();
                    }
                };
                img.onerror = () => {
                    // If loading fails, just resolve
                    resolve();
                };

                // Try to load with proxy or direct URL
                img.src = originalSrc.replace('googleusercontent.com', 'googleusercontent.com/proxy');
            });

            // Now capture everything
            const finalCanvas = await html2canvas(shareableContent, {
                scale: 2,
                backgroundColor: '#eeeeee',
                allowTaint: true,
                useCORS: true
            });

            // Convert to JPEG instead of PNG
            const image = finalCanvas.toDataURL('image/jpeg', 0.9);

            // For mobile devices
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                const blob = await (await fetch(image)).blob();
                const file = new File([blob], 'youtube-wrapped-2024.jpg', { type: 'image/jpeg' });

                if (navigator.share) {
                    try {
                        await navigator.share({
                            files: [file],
                            title: 'My YouTube Wrapped 2024'
                        });
                        return;
                    } catch (err) {
                        // Fall back to normal download if share fails
                        console.log('Share failed, falling back to download');
                    }
                }
            }

            // Fallback for desktop or if share fails
            const link = document.createElement('a');
            link.download = 'youtube-wrapped-2024.jpg';
            link.href = image;
            link.click();

            // Reset the profile image src
            profileImg.src = originalSrc;
        } catch (error) {
            console.error('Error generating image:', error);
        }
    };

    return (
        <div className="wrapped-page">
            <TopBar
                title="S-TIER STUFF"
                profileImageUrl={auth?.currentUser?.photoURL}
            />

            <div className="wrapped-content">
                <h1>
                    {wrappedData ? 'My YouTube Wrapped 🥳' : 'YouTube Wrapped'}
                </h1>
                <br></br>
                {isLoading ? (
                    <div className="loading-spinner">Loading...</div>
                ) : error ? (
                    <div className="error-message">
                        <p>We're so sorry! That didn't work for some reason :( It could be because you haven't liked any videos
                            on this account in 2024, or there's a bug in our code. </p>
                        <p>
                            Feel free to refresh or try again on a different browser or device. Or contact us at stierstuff@gmail.com.
                        </p>
                        {/* <p>Potential error: {error}</p> */}
                        {/* <button 
                            className="wrapped-button"
                            onClick={() => {
                                setError(null);  // Clear error
                                processLikedVideos();  // Try again
                            }}
                        >
                            Try Again
                        </button> */}

                    </div>
                ) : !wrappedData ? (
                    <>
                        <p className="description">
                            Get your 2024 YouTube wrapped! See how many videos you liked this year and which content creators
                            you loved the most.
                        </p>
                        {auth?.currentUser ? (
                            <>
                                <div className="wrapped-input-group">
                                    {/* <input
                                        type="text"
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.target.value)}
                                        placeholder="How did you hear about us?"
                                        className="feedback-input"
                                    /> */}
                                    <button
                                        className="wrapped-button"
                                        onClick={processLikedVideos}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Processing...' : 'Get My YouTube Wrapped!'}
                                    </button>
                                </div>
                                <p className="auth-note">(you may have to re-authenticate with Google)</p>
                            </>
                        ) : (
                            <button
                                className="wrapped-button"
                                onClick={signInWithGoogle}
                            >
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png"
                                    alt="Google logo"
                                    className="google-icon"
                                />
                                Sign in with Google to get your YouTube Wrapped
                            </button>
                        )}
                    </>
                ) : (
                    <div className="wrapped-results">
                        <div id="wrapped-shareable" className="wrapped-shareable">
                            <div className="wrapped-share-content">
                                {/* Profile Section with YouTube icon */}
                                <div className="wrapped-share-profile">
                                    <div className="wrapped-share-avatar-container">
                                        <img
                                            src={auth?.currentUser?.photoURL || '/default-avatar.png'}
                                            alt="Profile"
                                            className="wrapped-share-avatar"
                                        />
                                        <div className="wrapped-share-youtube-icon">
                                            <svg viewBox="0 0 24 24" fill="red">
                                                <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <h2>YouTube Wrapped 2024</h2>
                                </div>

                                {/* Stats */}
                                <div className="wrapped-share-stats">
                                    <div className="wrapped-share-stat">
                                        <span className="share-number">{wrappedData.totalVideos}</span>
                                        <span className="share-label">Videos Liked</span>
                                    </div>
                                    <div className="wrapped-share-stat">
                                        <span className="share-number">{wrappedData.totalChannels}</span>
                                        <span className="share-label">Unique Channels</span>
                                    </div>
                                </div>

                                <div className="wrapped-share-channels">
                                    <h3>Top 7 Channels</h3>
                                    {wrappedData.channelStats.slice(0, 7).map((channel, index) => (
                                        <div key={channel.channelId} className="wrapped-share-channel">
                                            <span className="share-rank">#{index + 1}</span>
                                            <span className="share-channel-name">{channel.name}</span>
                                            <span className="share-video-count">{channel.videoCount} videos</span>
                                        </div>
                                    ))}
                                </div>

                                <div className="wrapped-share-footer">
                                    <span>stierstuff.com/wrapped</span>
                                </div>
                            </div>
                        </div>

                        <button
                            className="wrapped-share-button"
                            onClick={generateShareImage}
                        >
                            Download As Image
                        </button>

                        <div className="wrapped-stat">
                            <div className="stat-group">
                                <div className="wrapped-stat-item">
                                    <p className="stat-number">{wrappedData.totalVideos}</p>
                                    <h2>Videos You Liked in 2024</h2>
                                </div>
                                <div className="stat-divider" />
                                <div className="wrapped-stat-item">
                                    <p className="stat-number">{wrappedData.totalChannels}</p>
                                    <h2>Unique Channels</h2>
                                </div>
                            </div>
                        </div>

                        <div className="wrapped-channels">
                            <h2>Your Top Channels</h2>
                            <div className="wrapped-channel-list">
                                {wrappedData.channelStats.map((channel, index) => (
                                    <div key={channel.channelId}>
                                        <div
                                            className={`wrapped-channel-item ${expandedChannelId === channel.channelId ? 'expanded' : ''}`}
                                            onClick={() => {
                                                if (expandedChannelId === channel.channelId) {
                                                    setExpandedChannelId(null);
                                                } else {
                                                    setExpandedChannelId(channel.channelId);
                                                    if (!channelVideos[channel.channelId]) {
                                                        getChannelVideos(channel.channelId);
                                                    }
                                                }
                                            }}
                                        >
                                            <span className="channel-rank">{index + 1}</span>
                                            <span className="channel-name">{channel.name}</span>
                                            <span className="channel-count">{channel.videoCount} videos</span>
                                            <span className="expand-icon">
                                                {expandedChannelId === channel.channelId ? '▼' : '▶'}
                                            </span>
                                        </div>

                                        {expandedChannelId === channel.channelId && (
                                            <div className="channel-videos">
                                                {channelVideos[channel.channelId] ? (
                                                    channelVideos[channel.channelId].map(video => (
                                                        <div key={video.videoId} className="channel-video-item">
                                                            <Link
                                                                to={`/video/${video.videoId}`}
                                                                className="video-link"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <img
                                                                    src={video.thumbnail}
                                                                    alt={video.title}
                                                                    className="video-thumbnail"
                                                                />
                                                                <div className="list-video-info">
                                                                    <div className="list-video-title">{video.title}</div>
                                                                    <div className="list-video-date">
                                                                        Liked on: {new Date(video.publishedAt).toLocaleDateString()}
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="loading-videos">Loading videos...</div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
};

export default YouTubeWrapped; 