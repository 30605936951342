import { GoogleAuthProvider, signInWithPopup, initializeAuth, browserPopupRedirectResolver, signInWithRedirect } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../firebase';  // Import your config
import { db } from '../firebase';  // Import Firestore
import { doc, updateDoc, arrayUnion, getDoc, setDoc, getDocs, collection, writeBatch } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Create a separate Firebase app instance for YouTube
const youtubeApp = initializeApp(firebaseConfig, 'youtubeApp');
// Create a separate auth instance for YouTube
const youtubeAuth = initializeAuth(youtubeApp, {
  popupRedirectResolver: browserPopupRedirectResolver
});

const createYoutubeProvider = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/youtube.readonly');
  
  // Add these if you have specific OAuth 2.0 credentials for YouTube
  provider.setCustomParameters({
    client_id: firebaseConfig.clientId,
    // Add any other OAuth 2.0 parameters you received
  });
  
  return provider;
};

export const getSubscribedChannels = async () => {
  try {
    const youtubeProvider = createYoutubeProvider();
    
    // Use the YouTube-specific auth instance
    const result = await signInWithPopup(youtubeAuth, youtubeProvider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;

    const response = await fetch(
      'https://www.googleapis.com/youtube/v3/subscriptions?part=snippet&mine=true&maxResults=50',
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Accept': 'application/json'
        }
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error('YouTube API Error:', errorData);
      throw new Error(`YouTube API error: ${errorData.error.message}`);
    }

    const data = await response.json();
    return data.items.map(item => ({
      channelId: item.snippet.resourceId.channelId,
      title: item.snippet.title,
      description: item.snippet.description,
      thumbnails: item.snippet.thumbnails
    }));

  } catch (error) {
    console.error("Error fetching YouTube subscriptions:", error);
    throw error;
  }
};

export const getLikedVideos = async () => {
  try {
    const youtubeProvider = createYoutubeProvider();
    
    let result;
    try {
      // Try popup first
      result = await signInWithPopup(youtubeAuth, youtubeProvider);
    } catch (error) {
      if (error.code === 'auth/popup-blocked') {
        console.log('Popup blocked, trying redirect...');
        // Fall back to redirect
        await signInWithRedirect(youtubeAuth, youtubeProvider);
        return; // The page will reload after redirect
      } else {
        throw error;
      }
    }

    const credential = GoogleAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;
    
    // Get liked playlist ID
    const channelResponse = await fetch(
      'https://www.googleapis.com/youtube/v3/channels?part=contentDetails&mine=true',
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Accept': 'application/json'
        }
      }
    );

    if (!channelResponse.ok) {
      throw new Error('Failed to get channel details');
    }

    const channelData = await channelResponse.json();
    if (!channelData.items || channelData.items.length === 0) {
        throw new Error("Could not access liked videos playlist. Please make sure your liked videos are not private.");
    }

    const likedPlaylistId = channelData.items[0]?.contentDetails?.relatedPlaylists?.likes;
    if (!likedPlaylistId) {
        throw new Error("Could not find liked videos playlist. Please make sure you have liked some videos.");
    }
    console.log('Liked playlist ID:', likedPlaylistId);

    let allVideos = [];
    let nextPageToken = null;
    let pageCount = 0;
    
    // Fetch all videos
    do {
      pageCount++;
      const pageParam = nextPageToken ? `&pageToken=${nextPageToken}` : '';
      console.log(`Fetching page ${pageCount}`);

      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=50&playlistId=${likedPlaylistId}${pageParam}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json'
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error('YouTube API Error:', errorData);
        throw new Error(`YouTube API error: ${errorData.error.message}`);
      }

      const data = await response.json();
      console.log(`Found ${data.items?.length || 0} videos on this page`);
      
      if (!data.items || data.items.length === 0) {
        console.log('No more videos found');
        break;
      }

      let foundOlderVideo = false;
      const videos = data.items.map(item => {
        const likeDate = new Date(item.snippet.publishedAt);
        if (likeDate.getFullYear() < 2024) {
          foundOlderVideo = true;
        }

        return {
          videoId: item.snippet.resourceId.videoId,
          title: item.snippet.title || 'Untitled Video',
          channelTitle: item.snippet.videoOwnerChannelTitle || 'Unknown Channel',
          channelId: item.snippet.videoOwnerChannelId || '',
          thumbnail: item.snippet.thumbnails?.default?.url || '',
          publishedAt: item.snippet.publishedAt || new Date().toISOString(),
          position: item.snippet.position || 0
        };
      });

      // Only add videos from 2024
      const videos2024 = videos.filter(video => 
        new Date(video.publishedAt).getFullYear() === 2024
      );
      allVideos = [...allVideos, ...videos2024];
      
      console.log(`Total 2024 videos so far: ${allVideos.length}`);

      // If we found a video from before 2024, stop fetching more pages
      if (foundOlderVideo) {
        console.log('Found videos from before 2024, stopping pagination');
        break;
      }

      nextPageToken = data.nextPageToken;

    } while (nextPageToken);

    // Store videos in Firestore
    const mainAuth = getAuth();
    const currentUser = mainAuth.currentUser;
    
    if (currentUser) {
      // Create a collection reference for the user's liked videos
      const videosCollectionRef = collection(db, "users", currentUser.uid, "likedVideos");
      
      // Create a batch for writing
      let batch = writeBatch(db);
      let operationCount = 0;
      
      for (const video of allVideos) {
        const videoRef = doc(videosCollectionRef, video.videoId);
        batch.set(videoRef, {
          ...video,
          addedAt: new Date().toISOString()
        });
        
        operationCount++;
        
        // Firestore batches are limited to 500 operations
        if (operationCount === 500) {
          await batch.commit();
          batch = writeBatch(db);
          operationCount = 0;
          console.log(`Committed batch of 500 videos`);
        }
      }
      
      // Commit any remaining operations
      if (operationCount > 0) {
        await batch.commit();
        console.log(`Committed final batch of ${operationCount} videos`);
      }

      // Update user document with metadata
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, {
        totalLikedVideos: allVideos.length,
        lastUpdated: new Date().toISOString()
      });
    }

    console.log(`Finished! Total videos found: ${allVideos.length}`);
    return allVideos;

  } catch (error) {
    console.error("Error processing liked videos:", error);
    // alert(error.message || "Unable to process liked videos. Please make sure your liked videos playlist is not private.");
    throw error;
  }
};

// Helper function to read all videos back
export const getAllStoredVideos = async (userId) => {
  const videosRef = collection(db, "users", userId, "likedVideos");
  const querySnapshot = await getDocs(videosRef);
  
  const videos = [];
  querySnapshot.forEach(doc => {
    videos.push(doc.data());
  });
  
  // Sort by position
  videos.sort((a, b) => a.position - b.position);
  
  return videos;
};